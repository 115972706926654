<template>
  <div>
    <div class="mb-3 d-flex align-items-center">
      <a-select
        style="min-width: 150px"
        class="mr-3"
        size="large"
        v-model="selectedWeek"
      >
        <a-select-option value="1">Week 1</a-select-option>
        <a-select-option value="2">Week 2</a-select-option>
      </a-select>
      <a-button
        :disabled="!selectedRows.length"
        class="ml-auto"
        size="large"
        type="primary"
      >
        <a-icon type="plus" /> Add {{ selectedRows.length }} Session(s)
        selected</a-button
      >
    </div>
    <a-row :gutter="16">
      <a-col :span="24">
        <a-table
          :columns="sessionColumns"
          :data-source="datas"
          :loading="loadingTableStudent"
          :row-selection="rowSelection"
          bordered
        >
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
const datas = [
  {
    key: 1,
    kelas: 'VII - 2',
    date: moment().format('DD MMM YYYY HH:mm'),
    isLive: true,
    assignmentType: 'Essay',
  },
  {
    key: 2,
    kelas: 'VII - 3',
    date: moment().format('DD MMM YYYY HH:mm'),
    isLive: true,
    assignmentType: 'Essay',
  },
]
const sessionColumns = [
  {
    title: 'Class',
    dataIndex: 'kelas',
    key: 'kelas',
    align: 'center',
    width: 100,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 200,
    scopedSlots: { customRender: 'date' },
  },
  {
    title: 'Live',
    dataIndex: 'isLive',
    key: 'isLive',
    align: 'center',
    scopedSlots: { customRender: 'isLive' },
  },
  {
    title: 'Assign',
    dataIndex: 'assignmentType',
    key: 'assignmentType',
    align: 'center',
    scopedSlots: { customRender: 'assignmentType' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  props: {
    dataProgram: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedRows: [],
      datas,
      sessionColumns,
      loadingTableStudent: false,
      selectedWeek: '1',
    }
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRows)
          // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
          this.selectedRows = selectedRows
        },
      }
    },
  },
}
</script>

<style>
</style>
